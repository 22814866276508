import React, { useState } from "react";
import data from "../data/data.js";

const Food = () => {
    const [foods,setFoods]=useState(data);
    const filterType = (category)=>{
        setFoods(
            data.filter((item)=>{
                return item.category === category;
            })
        )
    }
    const filterPrice = (price)=>{
        setFoods(
            data.filter((item)=>{
                return item.price === price;
            })
        )
    }
  return (
    <div className="font-[Poppins] m-auto px-4 py-12">
      <h1 className="text-purple-600 font-bold text-4xl text-center">Top choices of platform</h1>
      <div className="flex flex-col lg:flex-row justify-between">
        <div>
          <p className="font-bold text-gray-700">Filter Type :</p>
          <div className="flex justify-between flex-wrap">
            <button onClick={()=>setFoods(data)} className="m-1 border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white duration-300">All</button>
            <button onClick={()=>filterType('pizza')} className="m-1 border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white duration-300">Pizza</button>
            <button onClick={()=>filterType('burger')} className="m-1 border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white duration-300">Burger</button>
            <button onClick={()=>filterType('salad')} className="m-1 border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white duration-300">Salads</button>
            <button onClick={()=>filterType('chicken')} className="m-1 border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white duration-300">Chicken</button>
          </div>
        </div>
        <div>
        <p className="font-bold text-gray-700">Filter Price :</p>
          <div className="flex justify-between w-full max-w-[390px]">
            <button onClick={()=>filterPrice('₹')} className="m-1 border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white duration-300">₹</button>
            <button onClick={()=>filterPrice('₹₹')} className="m-1 border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white duration-300">₹₹</button>
            <button onClick={()=>filterPrice('₹₹₹')} className="m-1 border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white duration-300">₹₹₹</button>
            <button onClick={()=>filterPrice('₹₹₹₹')} className="m-1 border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white duration-300">₹₹₹₹</button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4 ">
            {foods.map((item,index)=>(
                <div key={index} className="border shadow-lg hover:scale-105 duration-300 rounded-xl ">
                    <img src={item.image} alt={item.name} className="w-full h-[200px] object-cover rounded-t-xl"/>
                    <div className="flex justify-between m-2">
                        <p className="font-bold">{item.name}</p>
                        <p>
                            <span className=" bg-purple-600 rounded-full p-1 text-white">
                                {item.price}
                            </span>
                        </p>
                    </div>
                </div>
            ))}
      </div>
    </div>
  );
};

export default Food;
